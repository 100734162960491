import { Client as AccountClient } from './client/account';
import { Client as MainClient } from './client/main';
import { Client as UserClient } from './client/user';
import { Client as BrandProfileClient } from './client/brandprofile';
import { Interaction } from './client/interaction';
import { Invite } from './client/invite';

/* eslint-disable dot-notation */

const clientMap = {}; // org id -> client configured for that organization
const client = {
    account(id) {
        const idx = `account_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new AccountClient({ endpoint: '/', serviceEndpoint: '/api/browser', accountId: id });
        }
        return clientMap[idx];
    },
    brandprofile() {
        if (!clientMap['brandprofile']) {
            clientMap['brandprofile'] = new BrandProfileClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['brandprofile'];
    },
    interaction(id) {
        const idx = `interaction_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new Interaction({ endpoint: '/', serviceEndpoint: '/api/browser', interactionId: id });
        }
        return clientMap[idx];
    },
    invite(id) {
        const idx = `invite_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new Invite({ endpoint: '/', serviceEndpoint: '/api/browser', inviteId: id });
        }
        return clientMap[idx];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    user(id) {
        const idx = `user_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: '/api/browser', userId: id });
        }
        return clientMap[idx];
    },
};

export default client;
