/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Entry {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/invite/${context.inviteId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get() {
        return getJson(`${this.url}/state/entry`, null, { requestHeaders: this.requestHeaders });
    }

    async check() {
        return postJsonAcceptJson(`${this.url}/check/entry`, null, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        return postJsonAcceptJson(`${this.url}/edit/entry`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Invite {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/invite/${context.inviteId}`;
        this.requestHeaders = context.requestHeaders;

        this.entry = new Entry(context);
    }

    async get() {
        return getJson(`${this.url}`, null, { requestHeaders: this.requestHeaders });
    }
}

export {
    Invite,
};
