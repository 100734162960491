/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete`, null, null, { requestHeaders: this.requestHeaders });
    }
}

class Form {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/form`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/form`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/form`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/form`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/form`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/form`, query, { requestHeaders: this.requestHeaders });
    }
}

class Client {
    constructor(context = {}) {
        this.currentAccount = new CurrentAccount(context);
        this.form = new Form(context);
    }
}

export {
    CurrentAccount,
    Client,
    Form,
};
