import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/invite/:inviteId',
            name: 'invite',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue'),
        },
        {
            path: '/form/:inviteId',
            name: 'form',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
