/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Service {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async getInfo() {
        return getJson(`${this.url}`);
    }

    async getVersion() {
        return getJson(`${this.url}/service/state/version`, null, { requestHeaders: this.requestHeaders });
    }
}

class Authn {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}/authn/session`, request, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async prefsRedirect(request) {
        return postJsonAcceptJson(`${this.url}/authn/prefs-redirect`, request, null, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/authn/logout`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkPasswordQuality(request) {
        return postJsonAcceptJson(`${this.url}/authn/check-password-quality`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Invite {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Client {
    constructor(context = {}) {
        this.authn = new Authn(context);
        this.invite = new Invite(context);
        this.service = new Service(context);
    }
}

export {
    Authn,
    Client,
    Invite,
    Service,
};
